// import PwaApp from "@/assets/images/icon-PWA-App.png";
import seedPhoneImg from "@/assets/images/home/cashClickApp/cashclick-app-mobile-seed.photo2.webp";
import dynamic from "next/dynamic";
// import Modal from "@/components/common/modal";
import Image from "next/image";
import { useState } from "react";

import CashclickDark from "@/assets/images/home/cashClickApp/CashclickDark.png";
import CashclickLight from "@/assets/images/home/cashClickApp/CashclickLight.png";
import BurstPuckerImageTitles from "@/components/common/burstPuckerImageTitles";
import { useMediaQuery } from "react-responsive";
import CashClickButtons from "./components/cashClick/CashClickButtons";

const Modal = dynamic(() => import("@/components/common/modal"), {
  ssr: false,
});

function CashClickAppDownload({
  title = "CashClick V2",
  subTitle = "Snap, Share, and Earn with Friends!",
  homeStyle,
}) {
  const [activeModal, setActiveModal] = useState(0);
  const isMobile = useMediaQuery({ query: "(max-width: 1023px)" });

  const [modal_pwaApp, setModal_pwaApp] = useState(false);
  function tog_pwaApp() {
    setModal_pwaApp(!modal_pwaApp);
    setActiveModal(1);
  }
  return (
    <>
      <div className="center">
        <div>
          <div className={homeStyle["home__cashClick__haed"]}>
            <div className={homeStyle["home__cashClick__title"]}>
              <div className={` burst ${homeStyle["home__cashClick__tablet"]}`}>
                <BurstPuckerImageTitles />
              </div>
              {title}
            </div>
            <div>
              {" "}
              <div className="some-icon">
                <Image
                  src={CashclickLight}
                  alt="CashclickLight"
                  width={51}
                  height={47}
                  style={{ objectFit: "contain" }}
                />
              </div>
              <div className="some-icon-dark">
                <Image
                  src={CashclickDark}
                  alt="CashclickDark"
                  width={51}
                  height={47}
                  style={{ objectFit: "contain" }}
                />
              </div>
            </div>
          </div>
        </div>
        <div className={homeStyle["home__cashClick__text1"]}>{subTitle}</div>
      </div>
      <div className={homeStyle["home__cashClick__box"]}>
        <div className="description__download_section center">
          {!isMobile ? (
            <div className={homeStyle["home__cashClick__details"]}>
              <div className="description__download_detail">
                <div className={homeStyle["home__cashClick__description"]}>
                  <div className={homeStyle["home__cashClick__text"]}>
                    SEED Rewards
                  </div>
                  <div className={homeStyle["home__cashClick__text2"]}>
                    Earn SEED tokens directly with a smoother and more rewarding
                    experience.
                  </div>
                </div>
                <div className={homeStyle["home__cashClick__description"]}>
                  <div className={homeStyle["home__cashClick__text"]}>
                    SEED Earnings
                  </div>
                  <div className={homeStyle["home__cashClick__text2"]}>
                    Unlock SEED tokens effortlessly with upgraded features and
                    instant rewards.
                  </div>
                </div>
              </div>
              <CashClickButtons homeStyle={homeStyle} />
            </div>
          ) : null}

          <div className={homeStyle["home__cashClick__photo"]}>
            <Image
              src={seedPhoneImg}
              loading="lazy"
              alt="cashclick-app-mobile-seed.photo"
              width={516}
              height={596}
              quality={100}
              style={{ objectFit: "contain" }}
            />
          </div>
        </div>
      </div>
      {isMobile ? (
        <div className={homeStyle["home__cashClick__box-mobile"]}>
          <div className={homeStyle["home__cashClick__section-mobile"]}>
            <div className={homeStyle["home__cashClick__text1-mobile"]}>
              {subTitle}
            </div>
            <div className={homeStyle["home__cashClick__section-text-mobile"]}>
              <div className={homeStyle["home__cashClick__description"]}>
                <div className={homeStyle["home__cashClick__text"]}>
                  Introducing Cash Click
                </div>
                <div className={homeStyle["home__cashClick__text2"]}>
                  our innovative app revolutionizing the way you earn online.
                  Simply browse, engage, and earn rewards in cryptocurrency –
                  it&apos;s that easy!
                </div>
              </div>
              <div className={homeStyle["home__cashClick__description"]}>
                <div className={homeStyle["home__cashClick__text"]}>
                  User-Friendly Interface
                </div>
                <div className={homeStyle["home__cashClick__text2"]}>
                  Navigate with ease and track your earnings in real-time. Earn
                  cryptocurrency seamlessly as you browse and click on content.
                </div>
              </div>
            </div>

            <CashClickButtons homeStyle={homeStyle} />
          </div>
        </div>
      ) : null}
    </>
  );
}

export default CashClickAppDownload;
